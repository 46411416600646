@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: "Raleway", sans-serif;
	}
}

.inline {
	display: inline;
}

.navlogo {
	width: 50px;
}

.accent-color {
	background-color: #009fc7;
}

.bg-accent {
	background-color: #009fc7;
}

.text-accent {
	color: #009fc7;
}

.home-section {
	height: 100vh;
}

.display-none {
	display: none;
}

.footer {
	text-align: center;
	padding: 0.5em 0;
	padding-bottom: 0.5rem;
}

.footer a {
	text-align: center;
	color: inherit;
	text-decoration: none;
}

footer h3 {
	max-width: 80vw;
	margin: 0 auto;
}

.footer_link {
	margin-bottom: 2rem;
	color: #009fc7;
}

.footer_link:hover,
.social-list_link:hover {
	/* opacity: 0.9; */
	transform: scale(1.55);
	transition: transform 0.4s ease;
}

.footer_link:hover {
	text-decoration: underline;
}

.self-center {
	display: flex;
	align-self: center;
	gap: 10px;
}

.social-list {
	list-style: none;
	outline: none;
	display: flex;
	justify-content: center;
	padding: 0;
}

.social-list_item {
	margin: 0 0.5em;
	color: #009fc7;
}

.social_list_img {
	margin-inline: 12px;
	width: 52px;
	height: 52px;
}

.final {
	margin-top: 40px;
	font-size: 0.75rem;
	color: var(--clr-accent);
}

.heroTitle {
	width: 80vw;
	max-width: 1200px;
}
.card:hover .card_content {
	transform: rotateY(180deg);
}

.card_content {
	text-align: center;
	position: relative;
	padding: 2em;
	transition: transform 1s;
	transform-style: preserve-3d;
	height: 250px;
}

.card_front,
.card_back {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 1em;
	backface-visibility: hidden;
}

.what {
	transform: translateZ(200px);
}

.card_back {
	transform: rotateY(0.5turn);
}

@media (max-width: 400px) {
	.heroTitle {
		display: none;
	}
}

.selfie {
	display: grid;
	width: 200px;
	height: 300px;
	align-self: center;
}

.about {
	max-width: 1200px;
}

.technology-icons {
	width: 30px;
	height: 30px;
	margin-bottom: 0.75em;
}

.technology-icons img {
	margin-inline: 2.5px;
}

.about2-top {
	display: flex;
}

@media (max-width: 700px) {
	.about2-top {
		flex-direction: column;
	}
	.technology-wrapper {
		align-items: center;
	}

	.technology-icons {
		justify-content: center;
	}
}

/* Tooltip container */
.tooltip {
	/* position: relative; */
	display: inline-block;
	border-bottom: 1px dotted black;
	min-width: 30px;
	margin-inline: 2px;
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;

	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltipicon {
	width: 30px;
	height: 30px;
}

.about2-top-left li {
	padding-block: 0.5rem;
}


@media screen and (max-width: 900px) {
	.portfolio-card a {
		width: 100%;
		flex-direction: column;
	}
	
	
}

@media screen and (max-width: 500px) {
	
	
	.portfolio-img-card img {
		width: 90vw;
		height: auto;
		margin: 0 auto
	}

	.portfolio-img-card {
		width: 90vw;
		height: auto;
		margin: 0 auto;
	}

	.portfolio-tech-card {
		width: 90vw;
		height: auto;
		margin: 0 auto;
	}
	
	.portfolio-tech-card p{
		max-width: 90vw;
		padding: 0.5em;
	}

	.portfolio-tech-card img {
		width: 40px;
	}
	.portfolio-card .card_content {
		padding: 1em;
	}
	
	.portfolio-card .card_front,
	.portfolio-card .card_back {
		padding: 1em;
	}
	
	.portfolio-card .card_front h3,
	.portfolio-card .card_back h3 {
		font-size: 1.5rem;
	}
	
}

@media screen and (max-width: 400px) {
	.heroButtons {
		display: flex;	
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}